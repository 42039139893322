// extracted by mini-css-extract-plugin
export var servicesToolkitSection = "ds_BN";
export var servicesToolkitSection__card = "ds_BP";
export var servicesToolkitSection__icons = "ds_BQ";
export var servicesToolkitSection__serviceToolkitIcon1 = "ds_BS";
export var servicesToolkitSection__serviceToolkitIcon10 = "ds_B2";
export var servicesToolkitSection__serviceToolkitIcon11 = "ds_B3";
export var servicesToolkitSection__serviceToolkitIcon12 = "ds_B4";
export var servicesToolkitSection__serviceToolkitIcon13 = "ds_B5";
export var servicesToolkitSection__serviceToolkitIcon14 = "ds_B6";
export var servicesToolkitSection__serviceToolkitIcon15 = "ds_B7";
export var servicesToolkitSection__serviceToolkitIcon16 = "ds_B8";
export var servicesToolkitSection__serviceToolkitIcon17 = "ds_B9";
export var servicesToolkitSection__serviceToolkitIcon18 = "ds_Cb";
export var servicesToolkitSection__serviceToolkitIcon19 = "ds_Cc";
export var servicesToolkitSection__serviceToolkitIcon2 = "ds_BT";
export var servicesToolkitSection__serviceToolkitIcon20 = "ds_Cd";
export var servicesToolkitSection__serviceToolkitIcon3 = "ds_BV";
export var servicesToolkitSection__serviceToolkitIcon4 = "ds_BW";
export var servicesToolkitSection__serviceToolkitIcon5 = "ds_BX";
export var servicesToolkitSection__serviceToolkitIcon6 = "ds_BY";
export var servicesToolkitSection__serviceToolkitIcon7 = "ds_BZ";
export var servicesToolkitSection__serviceToolkitIcon8 = "ds_B0";
export var servicesToolkitSection__serviceToolkitIcon9 = "ds_B1";
export var servicesToolkitSection__sprite = "ds_BR";
export var servicesToolkitSection__title = "ds_Cf";